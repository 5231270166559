import React, { useState, useEffect } from 'react';
import { storage, db } from '../firebaseConfig.js';
import { useAuth } from '../context/AuthContext.js';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, getDoc, query, where, addDoc, serverTimestamp, updateDoc, doc, GeoPoint } from 'firebase/firestore';
import './AddProperty.css';

const AddProperty = () => {
  const { currentUser } = useAuth();
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [formData, setFormData] = useState({
    inm: '',
    propietario: '',
    direccion: '',
    barrio: '',
    ciudad: '',
    operacion: '',
    tipo: '',
    publicacion: '',
    amb: '',
    personas: '',
    orientacion: '',
    mcub: '',
    mdes: '',
    expensas: '',
    estado: '',
    dep: false,
    cochera: false,
    baulera: false,
    vista: '',
    financia: false,
    permuta: false,
    credito: false,
    piscina: false,
    bprivado: false,
    quincho: false,
    amoblado: false,
    balcon: false,
    parque: false,
    patio: false,
    antiguedad: '',
    banos: '',
    dormitorios: '',
    llaves: '',
    motivo: '',
    descrip: '',
    idUsuario: currentUser?.uid,
    createdAt: null,
  });
  const [files, setFiles] = useState([]);
  const [inmobiliarias, setInmobiliarias] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [filteredInmobiliarias, setFilteredInmobiliarias] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);

  // Fetch user role and related data
  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        try {
          const usersCollection = collection(db, 'users');
          const userDocRef = doc(usersCollection, currentUser.uid);
          const userDoc = await getDoc(userDocRef);
  
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCurrentUserRole(userData.role);
            console.log('Rol del usuario:', userData.role);
            
            if (userData.role === 'asesor' || userData.role === 'inmobiliaria') {
              setFormData((prevData) => ({
                ...prevData,
                inm: userData.inmobiliaria // Actualiza el valor de "inmobiliaria" en el estado
              }));
            }
          } else {
            console.log('No se encontró el documento del usuario.');
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      }
    };
  
    fetchUserRole();
  }, [currentUser]);
  

  // Fetch inmobiliarias only if the user is admin
  useEffect(() => {
    const fetchInmobiliarias = async () => {
      try {
        const inmobiliariasCollection = collection(db, 'inmobiliarias');
        const snapshot = await getDocs(inmobiliariasCollection);
        const inmobiliariasData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setInmobiliarias(inmobiliariasData);
      } catch (error) {
        console.error('Error fetching inmobiliarias:', error);
      }
    };

    if (currentUserRole === 'admin') {
      fetchInmobiliarias();
    }
  }, [currentUserRole]);

  // Fetch clientes for the current user
  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const clientesCollection = collection(db, 'clientes');
        const q = query(clientesCollection, where('creadoPor', '==', currentUser.uid));
        const snapshot = await getDocs(q);
        const clientesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClientes(clientesData);
      } catch (error) {
        console.error('Error fetching clientes:', error);
      }
    };

    if (currentUser) {
      fetchClientes();
    }
  }, [currentUser]);

  // Handler for form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'inm' && currentUserRole !== 'asesor') {
      const searchTerm = value.toLowerCase();
      const filtered = inmobiliarias.filter(
        (inmobiliaria) =>
          (inmobiliaria.nombre || '').toLowerCase().includes(searchTerm) ||
          (inmobiliaria.registro || '').toLowerCase().includes(searchTerm)
      );
      setFilteredInmobiliarias(filtered);
    }

    if (name === 'propietario') {
      const searchTerm = value.toLowerCase();
      const filtered = clientes.filter((cliente) =>
        (cliente.nombre || '').toLowerCase().includes(searchTerm)
      );
      setFilteredClientes(filtered);
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleInmobiliariaSelect = (inmobiliaria) => {
    setFormData({
      ...formData,
      inm: inmobiliaria.id, // Guarda el ID de la inmobiliaria seleccionada
    });
    setFilteredInmobiliarias([]);
  };

  const handleClienteSelect = (cliente) => {
    setFormData({
      ...formData,
      propietario: `${cliente.nombre} (${cliente.phone})`,
    });
    setFilteredClientes([]);
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const getCoordinates = async (direccion) => {
    const apiKey = 'AIzaSyAMQ2JSPyDNmfDXpenLRDZuMLH1gLfIcn8';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(direccion)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK' && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return { latitude: location.lat, longitude: location.lng };
      } else {
        throw new Error('No se pudieron obtener las coordenadas.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      throw new Error('No se pudieron obtener las coordenadas.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.direccion || !formData.ciudad || !formData.barrio) {
      alert('Por favor, completa todos los campos obligatorios.');
      return;
    }
  
    try {
      const { direccion, ciudad, barrio } = formData;
      const fullAddress = `${direccion}, ${barrio}, ${ciudad}`;
      let ubicacionGeoPoint;
  
      try {
        const coordinates = await getCoordinates(fullAddress);
        ubicacionGeoPoint = new GeoPoint(coordinates.latitude, coordinates.longitude);
      } catch (error) {
        console.warn('No se pudieron obtener las coordenadas. Se usará un valor predeterminado.');
        ubicacionGeoPoint = new GeoPoint(0, 0); // Coordenadas predeterminadas
      }
  
      const propiedadData = {
        ...formData,
        createdAt: serverTimestamp(),
        ubicacion: ubicacionGeoPoint,
      };
  
      const docRef = await addDoc(collection(db, 'properties'), propiedadData);
  
      // Carga de archivos (si los hay)
      if (files.length > 0) {
        const uploadPromises = Array.from(files).map(async (file) => {
          const fileRef = ref(storage, `properties/${docRef.id}/${file.name}`);
          await uploadBytesResumable(fileRef, file);
          const downloadURL = await getDownloadURL(fileRef);
          return { fileName: file.name, fileURL: downloadURL };
        });
  
        const fileURLs = await Promise.all(uploadPromises);
  
        await updateDoc(doc(db, 'properties', docRef.id), {
          archivos: fileURLs,
        });
      }
  
      alert('Propiedad agregada exitosamente!');
    } catch (error) {
      console.error('Error al agregar la propiedad:', error);
      alert('Ocurrió un error al cargar la propiedad. Inténtalo de nuevo.');
    }
  };
  
  
  return (
    <div className="add-property">
      <h3>Cargar Nueva Propiedad</h3>
      <form onSubmit={handleSubmit}>
        {/* Mostrar el campo de inmobiliaria solo si el usuario es admin */}
        {currentUserRole === 'admin' ? (
        <div className="form-group">
          <label>Empresa - Martillero</label>
          <input
            type="text"
            className="form-control"
            name="inm"
            value={formData.inm}
            onChange={handleChange}
            placeholder="Buscar inmobiliaria (por nombre o registro)"
            autoComplete="off"
          />
          {/* Mostrar sugerencias solo cuando el usuario escribe */}
          {filteredInmobiliarias.length > 0 && (
            <ul className="suggestions">
              {filteredInmobiliarias.map(inm => (
                <li key={inm.id} onClick={() => handleInmobiliariaSelect(inm)}>
                  {inm.nombre} - {inm.registro}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : null }
        {/* Campo para propietario */}
        <div className="form-group">
          <label>Propietario</label>
          <input
            type="text"
            className="form-control"
            name="propietario"
            value={formData.propietario}
            onChange={handleChange}
            placeholder="Buscar propietario"
            autoComplete="off"
          />
          {filteredClientes.length > 0 && (
            <ul className="suggestions">
              {filteredClientes.map(cliente => (
                <li key={cliente.id} onClick={() => handleClienteSelect(cliente)}>
                  {cliente.nombre} - {cliente.phone}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label>Ciudad</label>
          <input type="text" className="form-control" name="ciudad" value={formData.ciudad} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Barrio</label>
          <input type="text" className="form-control" name="barrio" value={formData.barrio} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Dirección</label>
          <input type="text" className="form-control" name="direccion" value={formData.direccion} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Operación</label>
          <select name="operacion" className="form-control" value={formData.operacion} onChange={handleChange}>
            <option value="">Selecciona una operación</option>
            <option value="venta">Venta</option>
            <option value="alquiler temporario">Alquiler Temporario</option>
          </select>
        </div>
        <div className="form-group">
          <label>Tipo de propiedad</label>
          <select name="tipo" className="form-control" value={formData.tipo} onChange={handleChange}>
            <option value="">Selecciona un tipo</option>
            <option value="Campo">Campo</option>
            <option value="Casa">Casa</option>
            <option value="Cochera">Cochera</option>
            <option value="Departamento">Departamento</option>
            <option value="Duplex">Duplex</option>
            <option value="En pozo">En pozo</option>
            <option value="Galpon">Galpón</option>
            <option value="Hotel">Hotel</option>
            <option value="Local">Local</option>
            <option value="Lote">Lote</option>
            <option value="Oficina">Oficina</option>
            <option value="Ph">PH</option>
          </select>
        </div>
        <div className="form-group">
          <label>Cantidad de ambientes</label>
          <input type="number" className="form-control" name="amb" value={formData.amb} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Precio de publicación</label>
          <input type="number" className="form-control" name="publicacion" value={formData.publicacion} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Cantidad de personas</label>
          <input className="form-control" type="number" name="personas" value={formData.personas} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Ubicación</label>
          <select className="form-control" name="orientacion" value={formData.orientacion} onChange={handleChange}>
            <option value="">Selecciona una ubicación</option>
            <option value="Frente">Frente</option>
            <option value="Lateral">Lateral</option>
            <option value="Contrafrente">Contrafrente</option>
            <option value="Interior">Interior</option>
          </select>
        </div>
        <div className="form-group">
          <label>Estado de la propiedad</label>
          <select className="form-control" name="estado" value={formData.estado} onChange={handleChange}>
            <option value="">Selecciona una opción</option>
            <option value="A reciclar">A reciclar</option>
            <option value="Bueno">Bueno</option>
            <option value="Muy bueno">Muy bueno</option>
            <option value="Excelente">Excelente</option>
            <option value="A estrenar">A estrenar</option>
            <option value="Reciclado">Reciclado</option>
            <option value="Al pozo">Al pozo</option>
          </select>
        </div>
        <div className="form-group">
          <label>Metros cubiertos</label>
          <input className="form-control" type="number" name="mcub" value={formData.mcub} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Metros descubiertos</label>
          <input type="number" name="mdes" value={formData.mdes} onChange={handleChange} />
        </div>
        <div className="conteinerInputCheck">
          <div className="mb-3">
            <label>
              <input type="checkbox" name="dep" checked={formData.dep} onChange={handleChange} />
              Dependencia
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="cochera" checked={formData.cochera} onChange={handleChange} />
              Cochera
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="baulera" checked={formData.baulera} onChange={handleChange} />
              Baulera
            </label>
          </div>
          <div className="mb-3">
          <label>
            <input type="checkbox" name="financia" checked={formData.financia} onChange={handleChange} />
            Financia
          </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="permuta" checked={formData.permuta} onChange={handleChange} />
              Acepta permuta
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="credito" checked={formData.credito} onChange={handleChange} />
              Apto crédito
            </label>
          </div>
         
          <div className="mb-3">
            <label>
              <input type="checkbox" name="piscina" checked={formData.piscina} onChange={handleChange} />
              Piscina
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="quincho" checked={formData.quincho} onChange={handleChange} />
              Quincho
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="bprivado" checked={formData.bprivado} onChange={handleChange} />
              Barrio Privado
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="parque" checked={formData.parque} onChange={handleChange} />
              Parque
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="patio" checked={formData.patio} onChange={handleChange} />
              Patio
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="balcon" checked={formData.balcon} onChange={handleChange} />
              Balcón
            </label>
          </div>
          <div className="mb-3">
            <label>
              <input type="checkbox" name="amoblado" checked={formData.amoblado} onChange={handleChange} />
              Amoblado
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>Antiguedad</label>
          <input type="number" name="antiguedad" value={formData.antiguedad} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Cantidad de Baños</label>
          <input type="number" name="banos" value={formData.banos} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Cantidad de dormitorios</label>
          <input type="number" name="dormitorios" value={formData.dormitorios} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Expensas</label>
          <input type="number" name="expensas" value={formData.expensas} onChange={handleChange} />
        </div>
        
        <div className="form-group">
          <label>Vista</label>
          <select className="form-control" name="vista" value={formData.vista} onChange={handleChange}>
            <option value="">Selecciona una vista</option>
            <option value="Al mar">Al mar</option>
            <option value="Al río">Al río</option>
            <option value="A la calle">A la calle</option>
            <option value="Al parque">Al parque</option>
            <option value="Panorámica">Panorámica</option>
          </select>
        </div>
      
        <div className="form-group">
          <label>Entrega de llaves</label>
          <input type="text" className="form-control" name="llaves" value={formData.llaves} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Motivo de la venta</label>
          <textarea
            name="motivo" className="form-control"
            value={formData.motivo}
            onChange={handleChange}
            placeholder="Describe el motivo"
          />
        </div>
        <div className="form-group">
          <label>Descripción</label>
          <textarea className="form-control"
            name="descrip"
            value={formData.descrip}
            onChange={handleChange}
            placeholder="Descripción detallada de la propiedad"
          />
        </div>
        <div className="form-group">
          <label>Subir imágenes</label>
          <input type="file" className="form-control" multiple onChange={handleFileChange} />
        </div>
        <button type="submit">Agregar Propiedad</button>
      </form>
    </div>
  );
};

export default AddProperty;
