import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore'; 
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig.js';
import './Categorias.css';

const Categorias = () => {
  const [properties, setProperties] = useState([]);
  const [indexDepartamentos, setIndexDepartamentos] = useState(0);
  const [indexCasas, setIndexCasas] = useState(0);
  const [indexHighTicket, setIndexHighTicket] = useState(0);
  const [indexVistaAlMar, setIndexVistaAlMar] = useState(0);
  
  // Cambiar itemsToShow a un estado
  const [itemsToShow, setItemsToShow] = useState(4); 
  const navigate = useNavigate();

  // Función para calcular cuántos ítems mostrar según el ancho de la pantalla
  const calculateItemsToShow = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      return 8; // Máximo de 8 elementos
    } else if (width >= 992) {
      return 6;
    } else if (width >= 669) {
      return 4;
    } else if (width >= 430) {
      return 3;
    } else {
      return 2; // Mínimo de 2 elementos
    }
  };

  useEffect(() => {
    // Establecer el número de elementos al montar el componente
    setItemsToShow(calculateItemsToShow());

    // Añadir un listener para recalcular cuando se cambia el tamaño de la ventana
    const handleResize = () => {
      setItemsToShow(calculateItemsToShow());
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'properties'));
        const propertiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProperties(propertiesData);
      } catch (error) {
        console.error("Error fetching properties: ", error);
      }
    };

    fetchProperties();
  }, []);

  // Funciones para manejar los índices de cada slider
  const handleNext = (length, currentIndex, setCurrentIndex) => {
    if (currentIndex < length - itemsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = (currentIndex, setCurrentIndex) => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const departamentos = properties.filter(
    (prop) => prop.tipo === 'Departamento' && prop.operacion === 'venta' && prop.estado === 'Reciclado' && (!prop.venta || prop.venta === null)
  );
  
  const casas = properties.filter(
    (prop) => prop.tipo === 'Casa' && prop.operacion === 'venta' && (!prop.venta || prop.venta === null)
  );
  
  const highTicket = properties.filter(
    (prop) => prop.publicacion > 250000 && prop.operacion === 'venta' && (!prop.venta || prop.venta === null)
  );
  
  const vistaAlMar = properties.filter(
    (prop) => prop.vista === 'Al mar' && prop.operacion === 'venta' && (!prop.venta || prop.venta === null)
  );
  

  const handleViewProperty = (propertyId) => {
    navigate(`/property/${propertyId}`);
  };

  // Función para renderizar las propiedades dentro del slider
  const renderProperties = (properties, currentIndex) => {
    return properties.slice(currentIndex, currentIndex + itemsToShow).map((property) => (
      <div className="property-card-c" key={property.id}>
        {property.archivos && property.archivos.length > 0 && (
          <img 
            src={property.archivos[0].fileURL} 
            alt={`Imagen de ${property.amb} ambientes en ${property.barrio}`} 
            className="property-image" 
          />
        )}
        <div className="property-info">
          <h3>{property.amb} ambientes</h3>
          <p>Precio: ${property.publicacion}</p>
          <button onClick={() => handleViewProperty(property.id)}>Ver</button>
        </div>
      </div>
    ));
  };

  return (
    <div className="categorias-container">
      <div className="categoria">
        <h2>Departamentos Reciclados</h2>
        <div className="slider-container">
          <button 
            onClick={() => handlePrev(indexDepartamentos, setIndexDepartamentos)} 
            className="slider-button left"
          > 
            <i className="fa-solid fa-angles-left"></i> 
          </button>
          <div className="properties-slider">
            {renderProperties(departamentos, indexDepartamentos)}
          </div>
          <button 
            onClick={() => handleNext(departamentos.length, indexDepartamentos, setIndexDepartamentos)} 
            className="slider-button right"
          > 
            <i className="fa-solid fa-angles-right"></i> 
          </button>
        </div>
      </div>
      
      <div className="categoria">
        <h2>Casas</h2>
        <div className="slider-container">
          <button 
            onClick={() => handlePrev(indexCasas, setIndexCasas)} 
            className="slider-button left"
          > 
            <i className="fa-solid fa-angles-left"></i> 
          </button>
          <div className="properties-slider">
            {renderProperties(casas, indexCasas)}
          </div>
          <button 
            onClick={() => handleNext(casas.length, indexCasas, setIndexCasas)} 
            className="slider-button right"
          > 
            <i className="fa-solid fa-angles-right"></i> 
          </button>
        </div>
      </div>
      
      <div className="categoria">
        <h2>High Ticket (+250,000 USD)</h2>
        <div className="slider-container">
          <button 
            onClick={() => handlePrev(indexHighTicket, setIndexHighTicket)} 
            className="slider-button left"
          > 
            <i className="fa-solid fa-angles-left"></i> 
          </button>
          <div className="properties-slider">
            {renderProperties(highTicket, indexHighTicket)}
          </div>
          <button 
            onClick={() => handleNext(highTicket.length, indexHighTicket, setIndexHighTicket)} 
            className="slider-button right"
          > 
            <i className="fa-solid fa-angles-right"></i> 
          </button>
        </div>
      </div>
      
      <div className="categoria">
        <h2>Vista al Mar</h2>
        <div className="slider-container">
          <button 
            onClick={() => handlePrev(indexVistaAlMar, setIndexVistaAlMar)} 
            className="slider-button left"
          ><i className="fa-solid fa-angles-left"></i></button>
          <div className="properties-slider">
            {renderProperties(vistaAlMar, indexVistaAlMar)}
          </div>
          <button 
            onClick={() => handleNext(vistaAlMar.length, indexVistaAlMar, setIndexVistaAlMar)} 
            className="slider-button right"
          ><i className="fa-solid fa-angles-right"></i></button>
        </div>
      </div>
    </div>
  );
};

export default Categorias;
