import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore'; 
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig.js';
import './Categorias.css';

const CategoriasAlquiler = () => {
  const [properties, setProperties] = useState([]);
  const [indexMiramar, setIndexMiramar] = useState(0);
  const [indexMDP, setIndexMDP] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4); 
  const navigate = useNavigate();

  const calculateItemsToShow = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      return 8;
    } else if (width >= 992) {
      return 6;
    } else if (width >= 669) {
      return 4;
    } else if (width >= 430) {
      return 3;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    setItemsToShow(calculateItemsToShow());

    const handleResize = () => {
      setItemsToShow(calculateItemsToShow());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'properties'));
        const propertiesData = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(prop => prop.operacion === 'alquiler temporario' && (!prop.venta || prop.venta === null));
        setProperties(propertiesData);
      } catch (error) {
        console.error("Error fetching properties: ", error);
      }
    };

    fetchProperties();
  }, []);

  const handleNext = (length, currentIndex, setCurrentIndex) => {
    if (currentIndex < length - itemsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = (currentIndex, setCurrentIndex) => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleViewProperty = (propertyId) => {
    navigate(`/property/${propertyId}`);
  };

  const renderProperties = (properties, currentIndex) => {
    return properties.slice(currentIndex, currentIndex + itemsToShow).map((property) => (
      <div className="property-card-c" key={property.id}>
        {property.archivos && property.archivos.length > 0 && (
          <img 
            src={property.archivos[0].fileURL} 
            alt={`Imagen de ${property.amb} ambientes en ${property.barrio}`} 
            className="property-image" 
          />
        )}
        <div className="property-info">
          <h3>{property.amb} ambientes</h3>
          <p>Precio: ${property.publicacion}</p>
          <button onClick={() => handleViewProperty(property.id)}>Ver</button>
        </div>
      </div>
    ));
  };

  const propertiesMiramar = properties.filter(prop => prop.ciudad === 'Miramar');
  const propertiesMDP = properties.filter(prop => prop.ciudad === 'Mar Del Plata');

  return (
    <div className="categorias-container">
      <div className="categoria">
        <h2>Alquiler Temporario en Miramar</h2>
        <div className="slider-container">
          <button 
            onClick={() => handlePrev(indexMiramar, setIndexMiramar)} 
            className="slider-button left"
          >
            <i className="fa-solid fa-angles-left"></i>
          </button>
          <div className="properties-slider">
            {renderProperties(propertiesMiramar, indexMiramar)}
          </div>
          <button 
            onClick={() => handleNext(propertiesMiramar.length, indexMiramar, setIndexMiramar)} 
            className="slider-button right"
          >
            <i className="fa-solid fa-angles-right"></i>
          </button>
        </div>
      </div>

      <div className="categoria">
        <h2>Alquiler Temporario en Mar del Plata</h2>
        <div className="slider-container">
          <button 
            onClick={() => handlePrev(indexMDP, setIndexMDP)} 
            className="slider-button left"
          >
            <i className="fa-solid fa-angles-left"></i>
          </button>
          <div className="properties-slider">
            {renderProperties(propertiesMDP, indexMDP)}
          </div>
          <button 
            onClick={() => handleNext(propertiesMDP.length, indexMDP, setIndexMDP)} 
            className="slider-button right"
          >
            <i className="fa-solid fa-angles-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoriasAlquiler;
