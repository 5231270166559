import React, { useState, useEffect } from 'react';
import { auth, db } from './firebaseConfig.js'; // Usamos auth desde firebaseConfig
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import RegisterWithGoogle from './RegisterWithGoogle.js';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referrer, setReferrer] = useState(null);

  // Hook para obtener el referrer desde localStorage
  useEffect(() => {
    const storedReferrer = localStorage.getItem('referrer');
    if (storedReferrer) {
      setReferrer(storedReferrer);
    }
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
  
    // Lista de dominios permitidos
    const allowedDomains = ['gmail.com', 'hotmail.com', 'outlook.com'];
  
    // Obtiene el dominio del correo
    const emailDomain = email.split('@')[1];
  
    if (!allowedDomains.includes(emailDomain)) {
      alert(
        'El dominio del correo electrónico no está permitido. Solo se aceptan direcciones de Gmail, Hotmail y Outlook.'
      );
      return;
    }
  
    try {
      // Verifica si el email ya está registrado en Firestore
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        alert('El correo ya está registrado. Por favor, utiliza otro.');
        return;
      }
  
      // Crea un nuevo usuario
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Verifica si el usuario se autenticó correctamente
      if (!user) {
        throw new Error('Error en la autenticación. Intenta nuevamente.');
      }
  
      // Construye el objeto de datos para guardar en Firestore
      const userData = {
        email: user.email,
        role: 'user', // Cambia a 'admin' si es necesario
      };
  
      // Si existe un UID de referido, lo agrega al campo 'referido'
      if (referrer) {
        userData.referido = referrer;
      }
  
      // Guarda el usuario en Firestore
      await setDoc(doc(db, 'users', user.uid), userData);
  
      alert('Registro exitoso');
    } catch (error) {
      // Manejo de errores de autenticación
      switch (error.code) {
        case 'auth/email-already-in-use':
          alert('Este correo ya está en uso. Por favor, usa otro.');
          break;
        case 'auth/invalid-email':
          alert('El correo ingresado no es válido.');
          break;
        case 'auth/operation-not-allowed':
          alert('El registro de usuarios está deshabilitado.');
          break;
        case 'auth/weak-password':
          alert('La contraseña es muy débil. Debe tener al menos 6 caracteres.');
          break;
        default:
          console.error('Error de registro:', error);
          alert('Error en el registro. Inténtalo de nuevo.');
      }
    }
  };
  

  return (
    <div className="register-container">
      <div className="register-box">
        <h2>Registro</h2>
        <form onSubmit={handleRegister}>
          <div className="input-group">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingresa tu correo"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Crea una contraseña"
              required
            />
          </div>
          <button type="submit" className="register-button">Registrarse</button>
        </form>

        <p className="login-link">
          ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión aquí</Link>
        </p>

        <div className="google-register">
          <RegisterWithGoogle />
        </div>
      </div>
    </div>
  );
};

export default Register;
