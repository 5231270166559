import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, addDoc, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig.js';
import { useAuth } from './context/AuthContext.js';
import Loading from './components/Loading.js';

const Reservas = () => {
  const { propertyId } = useParams();
  const { currentUser } = useAuth();
  const [property, setProperty] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const reservationsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const propertyRef = doc(db, 'properties', propertyId);
        const propertySnap = await getDoc(propertyRef);

        if (propertySnap.exists()) {
          const propertyData = propertySnap.data();

          if (propertyData.idUsuario !== currentUser.uid) {
            alert('No tienes permiso para ver las reservas de esta propiedad.');
            navigate('/office');
          } else {
            setProperty(propertyData);
          }
        } else {
          console.log('La propiedad no existe.');
          navigate('/office');
        }
      } catch (error) {
        console.error('Error al obtener la propiedad:', error);
        navigate('/office');
      }
    };

    if (currentUser) {
      fetchProperty();
    }
  }, [currentUser, propertyId, navigate]);

  useEffect(() => {
    const fetchReservations = async () => {
      if (property) {
        try {
          const reservationsRef = collection(db, 'reservas');
          const q = query(reservationsRef, where('propertyId', '==', propertyId));
          const querySnapshot = await getDocs(q);
          const reservationsData = querySnapshot.docs.map(doc => doc.data());
          setReservations(reservationsData);
        } catch (error) {
          console.error('Error al obtener las reservas:', error);
        }
      }
    };

    fetchReservations();
  }, [property, propertyId]);

  const checkIfDatesAreAvailable = async (startDate, endDate) => {
    try {
      const reservationsRef = collection(db, 'reservas');
      const q = query(reservationsRef, where('propertyId', '==', propertyId));
      const querySnapshot = await getDocs(q);
      const reservedDates = querySnapshot.docs.map(doc => doc.data().fecha);

      let currentDate = new Date(startDate);
      const endDateObj = new Date(endDate);

      while (currentDate <= endDateObj) {
        const dateString = currentDate.toISOString().split('T')[0];
        if (reservedDates.includes(dateString)) {
          alert(`La fecha ${dateString} ya está reservada o bloqueada.`);
          return false;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return true;
    } catch (error) {
      console.error('Error al verificar la disponibilidad de las fechas:', error);
      return false;
    }
  };

  const handleBlockDate = async () => {
    if (!startDate || !endDate) {
      alert('Por favor, selecciona una fecha de inicio y una fecha de fin.');
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      alert('La fecha de inicio no puede ser posterior a la fecha de fin.');
      return;
    }

    const datesAreAvailable = await checkIfDatesAreAvailable(startDate, endDate);
    if (!datesAreAvailable) {
      return;
    }

    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);
    const newReservations = [];

    while (currentDate <= endDateObj) {
      const dateString = currentDate.toISOString().split('T')[0];
      newReservations.push({
        fecha: dateString,
        propertyId: propertyId,
        bloqueadoPor: currentUser.uid,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    try {
      const batchPromises = newReservations.map((reservation) =>
        addDoc(collection(db, 'reservas'), reservation)
      );

      await Promise.all(batchPromises);
      setReservations([...reservations, ...newReservations]);
      setStartDate('');
      setEndDate('');
      alert('Fechas bloqueadas correctamente.');
    } catch (error) {
      console.error('Error al bloquear las fechas:', error);
      alert('Hubo un error al bloquear las fechas.');
    }
  };

  const indexOfLastReservation = currentPage * reservationsPerPage;
  const indexOfFirstReservation = indexOfLastReservation - reservationsPerPage;
  const currentReservations = reservations.slice(indexOfFirstReservation, indexOfLastReservation);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!property) {
    return (
      <div id="carga" className="containerLoading">
        <Loading />
      </div>
    );
  }

  return (
    <div className="containerMisReservas">
      <h2>Reservas para {property.direccion}</h2>
      {reservations.length > 0 ? (
        <ul>
          {reservations.map((reservation, index) => (
            <li key={index}>
              <p>Fecha reservada: {reservation.fecha}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay reservas.</p>
      )}

      <div className="pagination">
        {Array.from({ length: Math.ceil(reservations.length / reservationsPerPage) }, (_, i) => (
          <button key={i + 1} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : 'btn moli-o'}>
            {i + 1}
          </button>
        ))}
      </div>

      <div>
        <h3>Bloquear un rango de fechas</h3>
        <div className="input-group-alq">
          <input
            className="inputBusAlq"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Fecha de inicio"
          />
          <input
            className="inputBusAlq"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Fecha de fin"
          />
          <button className="btn moli-o" onClick={handleBlockDate}>
            Bloquear Rango
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reservas;
