import { useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig.js";
import reservosBeach from "../assets/reservos-beach.jpeg";
import Loading from './Loading.js';
import './Rentals.css';
import CategoriasAlquiler from "./CategoriasAlquiler.js";
import RentalPropertyCard from './RentalPropertyCard.js';

const Rentals = () => {
  const [city, setCity] = useState("Mar del Plata");
  const [propertyType, setPropertyType] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [people, setPeople] = useState(1); // Agrega el estado de personas
  const [propertyCategory, setPropertyCategory] = useState("alquiler temporario"); // Agrega el estado de categoría
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const handleSearch = async () => {
    if (!checkInDate || !checkOutDate) {
      alert("Por favor, selecciona fechas válidas.");
      return;
    }
  
    const diffTime = new Date(checkOutDate) - new Date(checkInDate);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
  
    if (diffDays < 3) {
      alert("El alquiler debe ser por un mínimo de 3 noches.");
      return;
    }
   
  
    setLoading(true);
    setSearched(false);
  
    try {
      const q = query(
        collection(db, "properties"),
        where("operacion", "==", "alquiler temporario"),
        where("ciudad", "==", city),
        where("tipo", "==", propertyType),
        where("personas", "==", Number(people)) 
      );
  
      const querySnapshot = await getDocs(q);
      const filteredProperties = [];
  
      for (const doc of querySnapshot.docs) {
        const property = doc.data();
        const propertyId = doc.id;
  
        // Si no hay reservas creadas, salta la lógica de reservas y muestra la propiedad
        const checkInTimestamp = new Date(checkInDate).getTime();
        const checkOutTimestamp = new Date(checkOutDate).getTime();
  
        // Aquí puedes verificar si la colección 'reservas' tiene datos
        const reservationsQuery = query(
          collection(db, "reservas"),
          where("idPropiedad", "==", propertyId),
          where("estado", "==", "activa"),
          where("fechaFin", ">=", checkInTimestamp),
          where("fechaInicio", "<=", checkOutTimestamp)
        );
  
        const reservationsSnapshot = await getDocs(reservationsQuery);
  
        // Si no hay reservas (colección vacía o sin coincidencias), se muestra la propiedad
        if (reservationsSnapshot.empty) {
          filteredProperties.push(property);
        }
      }
  
      setProperties(filteredProperties);
    } catch (error) {
      console.error("Error obteniendo propiedades:", error);
    } finally {
      setLoading(false);
      setSearched(true);
    }
  };
  

  return (
    <div className="promo-alquileres">
      <div className="texto-promo-alquileres">
        <h2>¿Conocés a alguien que tiene una propiedad para alquilar?</h2>
        <h2>
          Recomendale Reservos y ganá $15.000 por cada amigo que ponga su
          propiedad en alquiler en nuestra página.
        </h2>

        <ul className="wrapper-icons sci">
          <li className="icon comentarios">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias%20https://reservos.com/index.php?codep=${encodeURIComponent(
                city
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-facebook"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=https://reservos.com/index.php?codep=${encodeURIComponent(
                city
              )}&title=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-linkedin"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios">
            <a
              href={`https://twitter.com/intent/tweet?text=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias%20https://reservos.com/index.php?codep=${encodeURIComponent(
                city
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-x-twitter"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios">
            <a
              href={`https://api.whatsapp.com/send?text=https://reservos.com/index.php?codep=${encodeURIComponent(
                city
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-whatsapp"></i>
              </span>
            </a>
          </li>
          <li
            className="icon comentarios"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://reservos.com/index.php?codep=${encodeURIComponent(
                  city
                )}`
              );
              alert("¡Enlace copiado!");
            }}
          >
            <span>
              <i className="fa-solid fa-link"></i>
            </span>
          </li>
        </ul>
      </div>

      <div className="img-promo-alquileres">
        <img src={reservosBeach} alt="Promocion de alquileres temporarios mar del plata" />
      </div>

      <CategoriasAlquiler/>

      <div className="container-buscador">
        <h2>Filtrá por lo que buscás</h2>
        <div className="buscador">
          <form name="filtroAlqui" method="post">
            <div className="input-group-alq">
              <label htmlFor="city">Ciudad</label>
              <input
                type="text" className="inputBusAlq"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Mar del Plata"
              />
            </div>

            <div className="input-group-alq">
              <label htmlFor="propertyType">Tipo de Propiedad</label>
              <select className="inputBusAlq"
                id="propertyType"
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <option value="Casa">Casa</option>
                <option value="Departamento">Departamento</option>
                <option value="Cabaña">Cabaña</option>
                <option value="PH">PH</option>
              </select>
            </div>

            <div className="input-group-alq">
              <label htmlFor="propertyCategory">Categoría</label>
              <select className="inputBusAlq" id="propertyCategory" onChange={(e) => setPropertyCategory(e.target.value)} >
                <option value="alquiler temporario">Alquiler temporario</option>
              </select>
            </div>

            <div className="input-group-alq">
              <label htmlFor="people">Personas</label>
              <input className="inputBusAlq"
                type="number"
                id="people"
                value={people}
                onChange={(e) => setPeople(e.target.value)}
                placeholder="Cantidad de personas"
              />
            </div>

            <div className="input-group-alq">
              <label htmlFor="checkInDate">Fecha de Entrada</label>
              <input className="inputBusAlq"
                type="date"
                id="checkInDate"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
              />
            </div>

            <div className="input-group-alq">
              <label htmlFor="checkOutDate">Fecha de Salida</label>
              <input className="inputBusAlq"
                type="date"
                id="checkOutDate"
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.target.value)}
              />
            </div>

            <div className="alquilerDiario">
              <button
                type="button"
                className="btn moli-o"
                onClick={handleSearch}
              >
                Buscar
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="rental-properties">
        {loading ? (
          <div className="rental-carga">
            <div id="carga" className="containerLoading">
              <Loading />
            </div>
          </div> // Indicador de carga
        ) : searched ? (
          properties.length > 0 ? (
            properties.map((property, index) => (
              <RentalPropertyCard 
                key={index} property={property} archivos={property.archivos || []}
                checkInDate={checkInDate} checkOutDate={checkOutDate}
              />
            ))
          ) : (
            <p>No se encontraron propiedades, intenta con otro tipo de propiedad, número de personas o fecha.</p>
          )
        ) : (
          <p>Usa el formulario para buscar propiedades.</p>
        )}
      </div>
    </div>
  );
};

export default Rentals;
